<template>
	<v-container class="pa-0" fluid v-if="mounted">
		<!-- TOOLBAR -->
		<v-row no-gutters :style="stickyTop" class="white">
			<v-col cols="12">
				<v-container class="pa-0">
					<v-toolbar flat dense>
						<v-toolbar-title>Merken</v-toolbar-title>
						<v-toolbar-items>
							<v-icon class="ml-3" @click="fetchBrands"
								>mdi-refresh</v-icon
							>
						</v-toolbar-items>
						<v-spacer />
						<v-toolbar-items>
							<v-icon @click="openDialogAddBrand"
								>mdi-plus-circle-outline</v-icon
							>
						</v-toolbar-items>
						<v-spacer />
						<v-text-field
							style="max-width: 300px"
							placeholder="zoek een merk"
							v-model="search"
							append-icon="mdi-magnify"
							clearable
							hide-details="auto"
							solo
							flat
						/>
					</v-toolbar>
				</v-container>
			</v-col>
			<v-col cols="12">
				<v-progress-linear
					v-if="loading"
					active
					indeterminate
					rounded
				></v-progress-linear>
				<v-divider v-else class="accent" style="padding-top: 2px" />
			</v-col>
		</v-row>
		<!-- DIALOG ADD -->
		<v-dialog v-model="dialogAddBrand">
			<v-card width="500" max-width="100%" class="mx-auto">
				<v-card-title> nieuw merk </v-card-title>
				<v-card-text>
					<v-text-field
						v-model="brandName"
						label="Naam"
						hide-details="auto"
						clearable
					/>
				</v-card-text>
				<v-divider />
				<v-card-actions>
					<v-btn
						text
						plain
						color="primary"
						:disabled="!brandName"
						@click="addBrand()"
					>
						toevoegen
					</v-btn>
					<v-spacer />
					<v-btn text plain @click="dialogAddBrand = false">
						annuleren
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<!-- BRANDS -->
		<v-container>
			<v-data-iterator
				class="mt-3"
				:items="brands"
				:search="search"
				item-key="brand_id"
				disable-pagination
				hide-default-footer
			>
				<template v-slot:default="{ items }">
					<v-row justify="space-between">
						<v-col
							v-for="rowObject in items"
							:key="rowObject.brand_id"
							cols="auto"
						>
							<v-card
								outlined
								width="150"
								flat
								hover
								@click="navigateToBrand(rowObject.brand_id)"
							>
								<v-card-title>
									<v-img
										position="center center"
										:src="
											rowObject.img_tn_url
												? rowObject.img_tn_url
												: '@/assets/imgnotfound.jpg'
										"
										width="100%"
										aspect-ratio="1"
										contain
									/>
								</v-card-title>
								<v-card-subtitle>
									{{ rowObject.brand_name }}
								</v-card-subtitle>
							</v-card>
						</v-col>
					</v-row>
				</template>
			</v-data-iterator>
		</v-container>
	</v-container>
</template>
<script>
	import caas from "@/services/caas";
	export default {
		name: "Brands",
		mounted() {
			this.fetchBrands();
			this.mounted = true;
		},
		data() {
			return {
				brandName: "",
				dialogAddBrand: false,
				loading: false,
				mounted: false,
				brands: [],
				search: "",
			};
		},
		computed: {
			stickyTop() {
				return {
					position: "sticky",
					top: this.$vuetify.application.top + 0 + "px",
					zIndex: 1,
				};
			},
		},
		methods: {
			addBrand() {
				const sdoName = "web/objects/dECatBrand.r";
				let params = {
					action: "create",
					rowObjUpd: [],
				};
				let x = {
					RowMod: "A",
					brand_name: this.brandName,
				};
				params.rowObjUpd.push(x);
				this.loading = true;
				caas.sdo(sdoName, params)
					.then((response) => {
						if (response.data.success) {
							this.$store.dispatch("session/showSuccess");
							let newId =
								response.data.success.data.RowObjUpd[0].brand_id;
							this.navigateToBrand(newId);
						} else if (response.data.error) {
							this.$store.dispatch(
								"session/showBottomMessage",
								response.data.error
							);
						}
					})
					.finally(() => {
						this.loading = false;
						this.dialogAddBrand = false;
						this.brandName = "";
					});
			},
			openDialogAddBrand() {
				this.dialogAddBrand = true;
			},
			fetchBrands() {
				this.loading = true;
				const sdoName = "web/objects/dECatBrand.r";
				const params = {
					action: "read",
					nbrOfRows: 9999,
					setOpenQuery: "for each ecat_brand no-lock indexed-reposition",
				};
				caas.sdo(sdoName, params)
					.then((response) => {
						if (response.data.success) {
							this.brands = response.data.success.data.RowObject;
						}
					})
					.finally(() => {
						this.loading = false;
					});
			},
			navigateToBrand(brand_id) {
				this.$router.push({
					name: "Brand",
					params: { id: brand_id },
				});
			},
		},
	};
</script>
